import { FC } from 'react'
import useShortsContext from 'contexts/ShortsContext/hook'
import StreakCounter from 'components/StreakCounter'
import { Link } from 'react-router-dom'

interface props {
  hide: () => void
}

const ShortsSidebarContent: FC<props> = ({ hide }) => {
  const { subjectName } = useShortsContext()

  return (
    <div className="w-full flex flex-col grow animate-fade-in">
      <span className="text-lg bg-yellow-200 rounded-xl text-kura p-4 font-semibold w-full text-center leading-[130%] mt-8">{subjectName} - Shorts</span>
      <div className="px-12 grow">
        <StreakCounter />
      </div>
      <div>
        <Link to="/shorts" onClick={hide} className="button-primary mt-4">
          Konec
        </Link>
      </div>
    </div>
  )
}

export default ShortsSidebarContent
